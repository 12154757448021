"use client";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import React from "react";
import Toolbar from "./Toolbar";
import { navbarHeight } from "../helpers/defaultValues";

const StyledNavbar = styled(AppBar, {
    shouldForwardProp: (prop) =>
        !["transparent", "navbarHeight"].includes(prop.toString()),
})<{
    transparent: boolean;
    navbarHeight: number;
}>(({ theme, transparent, navbarHeight }) => ({
    background: transparent
        ? "rgba(0, 0, 0, 0)"
        : theme.palette.primary.container,
    boxShadow: transparent ? "none" : theme.shadows[3],
    borderRadius: "24px",
    transition: "background 0.5s ease",
    position: "fixed",
    top: 8,
    height: `${navbarHeight}px`,
    paddingTop: "8px",
    paddingBottom: "8px",
    marginLeft: "8px",
    marginRight: "8px",
    width: "calc(100% - 16px);",
}));

type Props = {
    children?: React.ReactNode;
};

const Navbar = ({ children }: Props) => {
    const [transparent, setTransparent] = React.useState(true);

    const handleScroll = () => {
        const position = window.scrollY;
        if (position <= 0) {
            setTransparent(true);
        } else {
            setTransparent(false);
        }
    };

    React.useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <StyledNavbar transparent={transparent} navbarHeight={navbarHeight}>
            <Toolbar transparent={transparent} />
        </StyledNavbar>
    );
};

export default Navbar;
