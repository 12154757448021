"use client";

import React from "react";
import { useSearchParams } from "next/navigation";

export const Aieref = () => {
    const searchParams = useSearchParams();
    const aieref = searchParams?.get("aieref");
    if (aieref) {
        localStorage.setItem("aieref", aieref);
    }
    return null;
};
