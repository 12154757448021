"use client";

import React from "react";
import { useReportWebVitals } from "next/web-vitals";

export const WebVitals = () => {
    useReportWebVitals((metric) => {
        console.log(metric);
    });
    return null;
};
