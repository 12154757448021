"use client";
import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

const linkStyles = (selected: boolean): SxProps => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "64px",
    marginTop: "8px",
    marginBottom: "8px",
    textDecoration: "none",
    ".iconContainer": {
        width: "100%",
        padding: "8px",
        borderRadius: "32px",
        display: "flex",
        justifyContent: "center",
        transition: "0.2s",
        ...(selected && {
            backgroundColor: "primary.containerDim",
        }),
        ".MuiSvgIcon-root": {
            ...(selected
                ? {
                      color: "primary.onContainerDim",
                  }
                : {
                      color: "primary.onContainer",
                  }),
        },
    },
    ".title": {
        textAlign: "center",
        fontWeight: "bold",
        ...(selected
            ? {
                  color: "primary.onContainerDim",
              }
            : {
                  color: "primary.onContainer",
              }),
    },
    "&:hover": {
        ".iconContainer": {
            backgroundColor: "primary.containerDim",
        },
    },
});

export type Props = {
    text: string;
    route: string;
    icon: React.ReactNode;
};

const SidebarLink = ({ route, text, icon }: Props) => {
    const [selected, setSelected] = React.useState(false);
    const pathname = usePathname();

    const linkSx = linkStyles(selected);

    React.useEffect(() => {
        if (pathname?.split("/")[1] === route) setSelected(true);
        else setSelected(false);
    }, [pathname]);

    return (
        <Box component={Link} href={route} sx={linkSx}>
            <Box className="iconContainer">{icon}</Box>
            <Box className="textContainer">
                <Typography variant="caption" className="title">
                    {text}
                </Typography>
            </Box>
        </Box>
    );
};

export default SidebarLink;
