"use client";
import React from "react";
import Drawer from "./Drawer";
import SidebarLink, { Props as SidebarLinkProps } from "./SidebarLink";
import {
    AttachMoney,
    Book,
    ContactMail,
    DashboardOutlined,
    EditCalendar,
    Help,
    Info,
    Quiz,
} from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import { useIsMobile } from "../helpers/useIsMobile";

type Props = {};

const SideBar = (props: Props) => {
    const links: SidebarLinkProps[] = [
        {
            text: "Dashboard",
            route: "/dashboard",
            icon: <DashboardOutlined />,
        },
        {
            text: "Product",
            route: "/product",
            icon: <Help />,
        },
        {
            text: "Pricing",
            route: "/pricing",
            icon: <AttachMoney />,
        },
        {
            text: "About",
            route: "/about",
            icon: <Info />,
        },
        {
            text: "FAQ",
            route: "/faq",
            icon: <Quiz />,
        },
        {
            text: "Blog",
            route: "/blog",
            icon: <Book />,
        },
        {
            text: "Contact",
            route: "/contact",
            icon: <ContactMail />,
        },
        {
            text: "Workout Generator",
            route: "/workout-generator",
            icon: <EditCalendar />,
        },
    ];

    const isMobile = useIsMobile();

    return !isMobile ? null : (
        <Drawer>
            <Stack>
                {links.map((link) => (
                    <SidebarLink key={link.route} {...link} />
                ))}
            </Stack>
        </Drawer>
    );
};

export default SideBar;
