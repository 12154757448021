import { useWindowSize } from "./useWindowResize";
import { useLayout } from "../../context/LayoutContext";

export const useIsMobile = () => {
    const [layoutState] = useLayout();
    const { isMobile } = layoutState;
    const { width: windowWidth } = useWindowSize();
    const isBrowserMobile = !!windowWidth && windowWidth < 900;

    return isMobile || isBrowserMobile;
};
