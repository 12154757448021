"use client";
import React, { createContext, useState } from "react";

interface LayoutState {
    isMobile: boolean;
    drawerOpen: boolean;
    drawerWidth: number;
}

const useLayoutState = (layout: LayoutState) => useState<LayoutState>(layout);

export const LayoutContext = createContext<ReturnType<
    typeof useLayoutState
> | null>(null);

export const useLayout = () => {
    const context = React.useContext(LayoutContext);
    if (!context) {
        throw new Error("useLayout must be used within a LayoutProvider");
    }
    return context;
};

const LayoutProvider = ({
    children,
    isMobile,
}: {
    children: React.ReactNode;
    isMobile: boolean;
}): React.ReactElement => {
    const [state, setState] = useLayoutState({
        isMobile,
        drawerOpen: false,
        drawerWidth: 80,
    });

    return (
        <LayoutContext.Provider value={[state, setState]}>
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutProvider;
