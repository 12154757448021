import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import createPalette, {
    Palette,
    PaletteOptions,
} from "@mui/material/styles/createPalette";

export const colors: PaletteOptions = {
    primary: {
        base: "#648FFF",
        main: "#648FFF",
        contrastText: "#FFFFFF",
        container: "#DAE1FF",
        onContainer: "#001849",
        containerDim: "#B3C5FF",
        onContainerDim: "#003FA4",
    },
    secondary: {
        base: "#785EF0",
        main: "#5F43D6",
        contrastText: "#FFFFFF",
        container: "#E6DEFF",
        onContainer: "#1B0062",
        containerDim: "#C9BEFF",
        onContainerDim: "#4723BD",
    },
    tertiary: {
        base: "#F42C38",
        main: "#B90066",
        contrastText: "#FFFFFF",
        container: "#FFDAD6",
        onContainer: "#410002",
        containerDim: "#FFB4AB",
        onContainerDim: "#93000B",
    },
    background: {
        default: "#FEFBFF",
    },
};

const baseTheme = createTheme({
    palette: createPalette(colors),
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: "24px",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                    fontWeight: "bold",
                },
            },
        },
    },
});

export const theme = responsiveFontSizes(baseTheme);
