"use client";
import { SxProps } from "@mui/material";
import MuiToolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import React from "react";
import LogoImage from "./LogoImage";
import CallToActionFab from "./CallToActionFab";
import { RocketLaunchOutlined } from "@mui/icons-material";
import { useIsMobile } from "../helpers/useIsMobile";
import dynamic from "next/dynamic";

const DashboardButton = dynamic(() => import("./DashboardButton"));
const MenuButton = dynamic(() => import("./MenuButton"));
const LinkButton = dynamic(() => import("./LinkButton"));
const Link = dynamic(() => import("next/link"));

const LeftBoxStyles: SxProps = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexBasis: "100px",
    height: "100%",
};

const RightBoxStyles: SxProps = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flex: 1,
    height: "100%",
};

const ToolbarStyles: SxProps = {
    minHeight: "0 !important",
};

const buttonContainerStyles: SxProps = {
    alignItems: "center",
    justifyContent: "flex-end",
};

const hamburgerContainerStyles: SxProps = {
    alignItems: "center",
    justifyContent: "flex-end",
};

type Props = {
    transparent: boolean;
};

const Toolbar = ({ transparent }: Props) => {
    const isMobile = useIsMobile();

    const links: { text: string; route: string }[] = [
        {
            text: "How does it work?",
            route: "/product",
        },
        {
            text: "Pricing",
            route: "/pricing",
        },
        {
            text: "About",
            route: "/about",
        },
        {
            text: "FAQ",
            route: "/faq",
        },
        {
            text: "Blog",
            route: "/blog",
        },
        {
            text: "Contact",
            route: "/contact",
        },
        {
            text: "Workout Generator",
            route: "/workout-generator",
        },
    ];

    return (
        <MuiToolbar sx={ToolbarStyles}>
            <Box sx={LeftBoxStyles}>
                <LogoImage transparent={transparent} />
            </Box>
            <Box sx={RightBoxStyles}>
                <CallToActionFab
                    title="Get Started"
                    route="/dashboard"
                    size="small"
                >
                    <RocketLaunchOutlined />
                </CallToActionFab>
                {!isMobile ? (
                    <Box sx={buttonContainerStyles}>
                        {links.map((link) => (
                            <LinkButton
                                key={link.text}
                                text={link.text}
                                route={link.route}
                                white={transparent}
                            />
                        ))}
                        <DashboardButton
                            href="/dashboard"
                            white={transparent}
                            LinkComponent={Link}
                        >
                            Dashboard
                        </DashboardButton>
                    </Box>
                ) : (
                    <Box sx={hamburgerContainerStyles}>
                        <MenuButton white={transparent} />
                    </Box>
                )}
            </Box>
        </MuiToolbar>
    );
};

export default Toolbar;
