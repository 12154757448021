import(/* webpackMode: "eager" */ "/usr/src/app/components_v2/helpers/useAieref.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/components_v2/helpers/useWebVitals.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/components_v2/navigation/Navbar.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/components_v2/navigation/SideBar.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/context/LayoutContext.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/context/UserContext.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/usr/src/app/theme/ThemeRegistry.tsx")