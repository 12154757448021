"use client";
import { styled } from "@mui/material/styles";
import Link from "next/link";
import React from "react";
import Fab, { FabProps } from "@mui/material/Fab";

const StyledFab = styled(Fab)(({ theme }) => ({
    borderRadius: "12px",
    background: theme.palette.tertiary.base,
    color: theme.palette.tertiary.contrastText,
    marginRight: "12px",
    "&:hover": {
        backgroundColor: theme.palette.tertiary.main,
    },
}));

const CallToActionFab = ({ route, ...props }: FabProps & { route: string }) => {
    return <StyledFab {...props} LinkComponent={Link} href={route} />;
};

export default CallToActionFab;
