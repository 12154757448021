"use client";
import React, { createContext, useState } from "react";

interface UserState {
    userDetails: any | null;
}

const useUserState = (userDetails: any) =>
    useState<UserState>({
        userDetails,
    });

export const UserContext = createContext<ReturnType<
    typeof useUserState
> | null>(null);

export const useUser = () => {
    const context = React.useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};

const UserProvider = ({
    userDetails,
    children,
}: {
    userDetails: any;
    children: React.ReactNode;
}): React.ReactElement => {
    const [state, setState] = useUserState(userDetails);

    return (
        <UserContext.Provider value={[state, setState]}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
