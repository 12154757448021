"use client";
import React from "react";
import Image from "next/image";
import { styled } from "@mui/material";
import { navbarHeight } from "../helpers/defaultValues";
import Link from "next/link";
import { useIsMobile } from "../helpers/useIsMobile";

const StyledImageContainer = styled(Link)(({ theme }) => ({
    height: "100%",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
        width: "48px",
    },
    [theme.breakpoints.up("md")]: {
        width: "72px",
    },
    ".logo": {
        objectFit: "contain",
    },
}));

type Props = {
    transparent: boolean;
};

const LogoImage = ({ transparent }: Props) => {
    const isMobile = useIsMobile();

    return (
        <StyledImageContainer href={"/"}>
            <Image
                src={
                    transparent
                        ? "/images/logo-small-white.svg"
                        : "/images/AI1024x1024.png"
                }
                alt="AIEndurance Logo"
                width={!isMobile ? 48 : 32}
                height={navbarHeight - 16}
                className="logo"
            />
        </StyledImageContainer>
    );
};

export default LogoImage;
