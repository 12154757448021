"use client";
import React from "react";
import { SxProps } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { useLayout } from "../../context/LayoutContext";

const drawerStyles = (drawerWidth: number): SxProps => ({
    ".MuiDrawer-paper": {
        borderRadius: "16px 0px 0px 16px",
        width: drawerWidth,
        backgroundColor: "primary.container",
        padding: "8px",
        height: "calc(100vh - 16px)",
        marginTop: "8px",
    },
});

type Props = {
    children: React.ReactNode;
};

const Drawer = ({ children }: Props) => {
    // const dispatch = useAppDispatch();
    // const { drawerOpen, drawerWidth } = useAppSelector((state) => state.layout);
    const [layoutState, setLayoutState] = useLayout();

    const { drawerOpen, drawerWidth } = layoutState;

    const drawerSx = drawerStyles(drawerWidth);

    const closeDrawer = () => {
        setLayoutState({
            ...layoutState,
            drawerOpen: false,
        });
    };

    return (
        <MuiDrawer
            open={drawerOpen}
            onClose={closeDrawer}
            anchor="right"
            sx={drawerSx}
        >
            {children}
        </MuiDrawer>
    );
};

export default Drawer;
